@font-face {
	font-family: "Poppins-Light";
	src: local("Poppins-Light"), url(fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins-Medium";
	src: local("Poppins-Medium"), url(fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins-Bold";
	src: local("Poppins-Bold"), url(fonts/Poppins-Bold.ttf) format("truetype");
}

body {
	margin: 0;
	font-family: "Poppins-Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body .amplify-button,
.sections-wrapper .section-header,
.sections-wrapper .tile-header {
	font-family: "Poppins-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
	"Droid Sans", "Helvetica Neue", sans-serif;
}

body .amplify-heading {
	font-family: "Poppins-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
	"Droid Sans", "Helvetica Neue", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, #root, #root > div, #root > div > div {
	/*min-height: 100dvh;*/
	/*height: 100dvh;*/
	height: 100%;
}

.table-scroll-wrapper {
	max-width: fit-content;
	min-width: 60%;
	margin: 0 auto;
	/*border: 1px solid red;*/
}

.sections-datatable-scroll-wrapper {
	margin: 0 auto;
	width: 90%;
}

div .portal-table-1	{
	border-collapse: separate;
	/*noinspection CssInvalidPropertyValue*/
	max-width: -moz-fit-content;
	max-width: fit-content;
	overflow-x: auto;
	white-space: nowrap;

	min-width: 100%;

	margin: 1em auto 1em;
	width: unset;

	border: 2px solid #D6D8D8;
	border-spacing: 0;

	border-radius: 5px;
	/*font-family: Arial, sans-serif;*/
}

/* This feature is not currently supported in Firefox - specify minimum column width if cells contain Buttons */
div .portal-table-1 td:has(> button) {
	width: 1%;
}

div .portal-table-1 th,
div .portal-table-1 td {
	border: none;
}

/* Table header */
div .portal-table-1 .amplify-table__head {
	background-color: #D6D8D8;
	color: #666;
}

div .portal-table-1 .amplify-table__head .amplify-table__row {
	border: 2px solid #D6D8D8;
	border-bottom: none;
}

div .portal-table-1 .amplify-table__head .amplify-table__row td {
	display: table-cell;
}

/* Table header cells */
div .portal-table-1 .amplify-table__th {
	/*padding: 8px;*/
	padding: 8px 21px;
	text-align: left;
	border: unset;
	color: unset;
}

div .portal-table-1 .amplify-table__body .amplify-table__row:first-child td {
	border-top: none;
}

div .portal-table-1 .amplify-table__body .amplify-table__row:last-child td {
	border-bottom: none;
}

div .portal-table-1 .amplify-table__body .amplify-table__row td:first-child  {
	border-left: none;
}

div .portal-table-1 .amplify-table__body .amplify-table__row td:last-child  {
	border-right: none;
}

div .portal-table-1 .amplify-table__body .amplify-table__row:nth-child(odd) {
	/*background-color: #d6d8d8;*/
}

div .portal-table-1 .amplify-table__body .amplify-table__row:nth-child(even) {
	background-color: #eff0f0;
}

div .portal-table-1.amplify-table[data-highlightonhover=true] .amplify-table__row:not(.amplify-table__head *):hover {
	background-color: #e0e0e0;
}

.portal-table-1 th {
	font-size: 14px;
	line-height: 1.4;
	padding: 21px;
	text-align: left;
}

.portal-table-1 td {
	font-size: 15px;
	line-height: 1.4;
	padding: 21px;
	text-align: left;
}

.portal-table-1 a {
	text-decoration: underline;
	cursor: pointer;
}

.portal-table-1 a:hover {
	text-shadow: 0 0 0.5px black;
}

/* Amplify Button - Default (outlined) */
div div .amplify-button,
div div .amplify-button:focus,
div div .amplify-button--link,
div div .amplify-button--link:focus {
	padding: 10px 24px;
	cursor: pointer;
	border-radius: 0.75em;
	/*font-family: Calibri, sans-serif;*/
	background-color: white;
	color: #292981;
	border: 1px solid #292981;
	font-size: 14px;
	font-weight: initial;
}

div div .amplify-button:hover,
div div .amplify-button--link:hover {
	color: #4949d9;
	border-color: #4949d9;
}

div div .amplify-button--disabled {
	opacity: 70%;
	cursor: not-allowed !important;
}

.search-field-wrapper .MuiInputBase-root {
	border-radius: 0.75em;
}

/* Amplify Button - Primary */
div div .amplify-button--primary {
	background-color: #292981;
	border: 1px solid;
	color: white;
	padding: 10px 24px;
	cursor: pointer;
	border-radius: 0.75em;
}

div div .amplify-button--primary:hover {
	background-color: #4949d9;
	color: white;
}

/* Button container */
div .button-container {
	display: flex;
	justify-content: flex-end;
}

.sections-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 3em;
}

.sections-wrapper .sections-amplify-expander {
	margin: 3.5em auto;
	display: flex;
	width: 80%;
	flex-direction: column;
	background: #eee;
}

.sections-amplify-expander .amplify-expander__header {
	font-weight: bold;
}

.sections-wrapper .amplify-expander__content {
	padding-inline-start: unset;
	padding-inline-end: unset;
}

.sections-wrapper .amplify-expander__content__text {
	padding-block-start: unset;
	padding-block-end: unset;
}

.sections-wrapper .sections-inner-header {
	text-align: left;
	margin: 0.5em 1em;
}

.sections-wrapper .inline-form-sections .form-wrapper {
	box-shadow: unset !important;
	background: unset !important;
}

.amplify-expander .amplify-expander__item:focus-within {
	box-shadow: unset;
}

section .header-with-back-button {
	display: flex;
	width: 80%;
	margin: 3.5em auto 0.5em;
}

section .header-with-back-button .header-with-back-button-grid-wrapper {
	display: grid;
	grid-template-columns: auto 1fr;
	width: 100%;
}

section .header-with-back-button .header-with-back-button-grid-wrapper .header-back-button {
	grid-area: 1 / 1 / 2 / 2;
	z-index: 1;
}

section .header-with-back-button .header-with-back-button-grid-wrapper .second-item {
	grid-area: 1 / 1 / 2 / 4;
	display: flex;
	justify-content: center;
}

@media (max-width:750px) {
	section .header-with-back-button .header-with-back-button-grid-wrapper {
		display: unset;
	}
}

section .header-with-back-button > * {
	align-self: center;
}

.pages-content-wrapper {
	width: 100%;
	grid-area: 2 / 1 / 3 / 2;

	display: flex;
	flex-direction: row;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.pages-content-wrapper > section {
	width: 100%;
	min-width: 0;
	/*height: calc(100vh - 80px);*/
	overflow: auto;
}

div .custom-table-scroll-view {
	margin-left: 1em;
	margin-right: 1em;
}

.mfa-modal-window {
	background: white;
	border: 1px solid #ccc;
	transition: 1.1s ease-out;
	box-shadow: -1rem 1rem 1rem rgba(0, 0, 0, 0.2);
	filter: blur(0);
	opacity: 1;
	visibility: visible;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

div .with-line-breaks {
	white-space: pre-wrap;
}

.persistence-drawer-fix-nav {
	display: flex;
}

.persistence-drawer-fix .MuiDrawer-paper {
	position: unset;
}

.persistence-drawer-fix > .MuiPaper-root {
	overflow-x: hidden;
}

.amplify-expander__header button {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

div .amplify-expander {
	box-shadow: none;
}

.main-menu-button {
	box-shadow: none !important;
}

.main-menu-button:hover {
	color: #A8E7E6 !important;
}

.account-button-top-bar > p:hover,
.account-button-top-bar-active > p {
	color: #A8E7E6 !important;
}

.amplify-table .amplify-button--menu {
	border: 1px solid #D6D8D8;
	border-radius: unset;
}

.amplify-table .amplify-button--menu:hover {
	border: 1px solid #D6D8D8;
	background: white;
	border-radius: unset;
}

.amplify-table .triangle-table-button {
	padding: 0.75em 1em !important;
	border: 1px solid #666;
	color: #666;
}

