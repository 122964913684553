.alert-messages-wrapper {
	position: fixed;
	z-index: 1202;
	top: 1rem;
	left: 10px;
	right: 10px;
}
.alert-messages-wrapper .amplify-alert {
	margin-bottom: 10px;
}
