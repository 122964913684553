.accounts-modal-window {
	display: flex;
	outline: none;
	visibility: visible;

	flex-direction: column;
	align-items: center;

	position: absolute;
	top: 83px;
	right: 5px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	background-color: #fff;
	border: 1px solid #e1e4e8;
	border-radius: 6px;
	width: 300px;
	max-width: 95%;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	padding: 0.5em;
}

.accounts-modal-window > * {
	width: 100%;
	margin: 0.5em 0;
}

.accounts-modal-window > *:last-child {
	margin-bottom: 0;
}

.accounts-modal-window a {
	display: flex;
	align-items: center;
	/*color: #0366d6;*/
	text-decoration: none;
	cursor: pointer;
	color: #292981;
}

.accounts-modal-window a:hover {
	color: #4949d9;
}
