.navbar {
	/*background-color: #060b26;*/
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.menu-bars {
	margin-left: 2rem;
	font-size: 2rem;
	background: none;
}

.nav-menu {
	/*background-color: #060b26;*/
	width: 250px;
	/*height: 100dvh;*/
	height: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: -100%;
	transition: 850ms;
}

.nav-menu.active {
	left: 0;
	transition: 350ms;
}

.nav-text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 0 8px 16px;
	list-style: none;
	height: 60px;
}

.nav-text a {
	text-decoration: none;
	/*color: #f5f5f5;*/
	font-size: 18px;
	width: 95%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 16px;
	border-radius: 4px;
}

.admin-text {
	text-decoration: none;
	text-align: center;
	/*color: #f5f5f5;*/
	font-size: 22px;
	width: 95%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 20px;
	border-radius: 4px;
}



.nav-text a:hover {
	/*background-color: #1a83ff;*/
}

.nav-menu-items {
	width: 100%;

}

.navbar-toggle {
	/*background-color: #060b26;*/
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

span {
	/*margin-left: 16px;*/
}

ul .list-item-wrapper {
	display: flex;
	justify-content: center;
}

.list-item-link-wrapper {
	display: flex;
	text-decoration: none;
	width: 100%;
}

.list-item-link-wrapper .list-item-link-icon-wrapper {
	min-width: unset;
	/*color: #ccc;*/
}

.list-item-link-icon-wrapper > * {
	margin: 0.5em 0.5em;
}

.list-item-link-wrapper .list-item-link-button-wrapper {
	width: 100%;
	padding: 0;
	/*color: #ccc;*/
}

.list-item-wrapper .list-item-link-wrapper:hover .list-item-link-icon-wrapper {
	background-color: rgba(0, 0, 0, 0.04);
	opacity: 60%;
	transition-delay: 50ms;
}

.list-item-link-button-text-wrapper {
	white-space: nowrap;
	width: 100%;
	/*color: #060b26;*/
}

/*.amplify-button.hamburger-button {*/
/*	!*top: 5.5em;*!*/
/*	top: 0.8em;*/
/*	left: 1em;*/
/*	color: #ccc;*/
/*	z-index: 1;*/
/*	border: 1px solid #ccc;*/
/*	background: unset;*/
/*}*/

/*.amplify-button.hamburger-button:focus {*/
/*	border: 1px solid #ccc;*/
/*	background: unset;*/
/*}*/
